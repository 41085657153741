import React from "react"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import { PageSeo } from "../components/PageSeo"
import "./styles/company.scss"

const WhyCredenceTemplate = ({ data }) => {
  const { markdownRemark: markup } = data
  const { name, blurb, banner } = markup.frontmatter
  return (
    <Layout>
      <section className="WhyCredenceTemplate">
        <Banner data={banner} />
        <div className="container">
          <h2 className="text-center py-3 py-lg-5">{name}</h2>
          {blurb.length &&
            blurb.map((item, index) => (
              <div key={`whyUs_${index}`}>
                <div className={`row border-start mb-2 mb-lg-5`}>
                  <div className="col-12 col-lg-12 content">
                    <h4 className="text-uppercase text-muted subheading">
                      {item.heading}
                    </h4>
                    <ReactMarkdown children={item.content}></ReactMarkdown>
                  </div>
                  {item.image && (
                    <div className="col-12 col-lg-9 mb-3 ms-auto">
                      <GatsbyImage
                        image={item.image.childImageSharp.gatsbyImageData}
                        alt={item.heading}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </section>
    </Layout>
  )
}

export const WhyUsQuery = graphql`
  query WhyUsQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        banner {
          heading
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
        blurb {
          heading
          content
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`
export default WhyCredenceTemplate
export const Head = () => (
  <PageSeo
    title="Why Credence Analytics | Ranked #1 Investment Management Software Solution Provider"
    description="WHY CREDENCE ANALYTICS, Mature and Comprehensive Solutions, Rich Domain Expertise and Self Implementation, Quick Go-Live, Open Architecture, Product Road Map Upgrades, Client Experience, After Sales Support"
    keywords="Credence live Clients, Credence live customers, Mature and Comprehensive Solutions, Rich Domain Expertise and Self Implementation, Quick Go-Live, Open Architecture, Product Road Map Upgrades, Client Experience, After Sales Support"
  />
)
